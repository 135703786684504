<template>
  <div>
    <h1>{{ title }}</h1>
    <p>{{ Date }}<a :href="link">https://disp.tmr-aws-dev.51vms.net/#/waiter-page</a></p>
  </div>
</template>

<script>
import emitter from "@/utils/emitter";
export default {
  name: 'HeaderComponent',
  data() {
    return {
      mode:'',
      first:'',
      last:'',
      title: '星野トマムダッシュボードについて DASHBOARD',
      link: 'https://disp.tmr-aws-dev.51vms.net/#/waiter-page'
    }
  },
  methods:{
    getparams(mode,first,last){
      this.mode=mode;
      this.first=first;
      this.last=last;
    },
    formattedDate(timestamp){
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate
    },
  },
  created() {

    //刚进来显示本天到之前一个星期
    let time=new Date();
    let dateString = time.getTime();
    let last= this.formattedDate(dateString);

    const result = new Date();
    result.setTime(dateString - 6 * 24 * 60 * 60 * 1000);
    let first = this.formattedDate(result);
    this.getparams("all",first,last);

    emitter.on("set_data",(args)=>{
      if(args[0]==null){
        args[0]="all";
      }
      //第一次进来是今天
      if(args[2]==null){
        let time=new Date();
        let dateString = time.getTime();
        args[2]= this.formattedDate(dateString);
      }
      //七天前时间
      if(args[1]==null) {
        let time=new Date();
        let dateString = time.getTime();
        const result = new Date();
        result.setTime(dateString - 6 * 24 * 60 * 60 * 1000);
        args[1] = this.formattedDate(result);
      }
       this.getparams(args[0],args[1],args[2]);
    });

  },
  computed:{
    Date(){
      return "Date : "+this.first+" ~ "+this.last+" / "+this.mode+" | website: ";
    }
  }
}
</script>
<style>
h1 {
  font-size: 30px;
  color: #dfdfdf;
  margin: 0;
  padding: 0;
}
p {
  font-size: 15px;
  color: #dfdfdf;
  margin: 0;
  padding: 0;
}
a {
  font-size: 15px;
  color: greenyellow;
  margin: 0;
  padding: 0;
}
.float-left {
  float: left;
  text-align: left;
}
</style>
