<template>
  <div id="app">
    <ConfigPanel/>
    <div class="main-container">
      <div class="flex-container">
        <HeaderComponent/>
      </div>
      <div class="flex-container">
        <MiddleComponent/>
      </div>
      <div class="flex-container">
        <TableUsage/>
        <TableTimeUsage/>
      </div>
      <div class="flex-container">
        <TableAverageTime/>
      </div>
      <div class="flex-container">
        <MonitoringTable/>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue'
import ConfigPanel from '@/components/ConfigPanel.vue'
import TableUsage from '@/components/TableUsage.vue'
import TableTimeUsage from "@/components/TableTimeUsage.vue";
import TableAverageTime from "@/components/TableAverageTime.vue";
import MonitoringTable from "@/components/MonitoringTable.vue";
import MiddleComponent from "@/components/MiddleComponent.vue";
export default {
  name: 'App',
  components: {
    HeaderComponent,
    ConfigPanel,
    TableUsage,
    TableTimeUsage,
    MonitoringTable,
    TableAverageTime,
    MiddleComponent
  }
}
</script>


<style scoped>
.flex-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 4em;
  margin-bottom: 2em;
}

.main-container {
  margin-left: 5em; /* adjust as needed */
  margin-right: 5em; /* adjust as needed */
}
</style>
