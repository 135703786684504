import {createRouter ,createWebHashHistory} from 'vue-router'
import WaiterPage from "@/components/WaiterPage.vue";
const router=createRouter({
    history:createWebHashHistory(),
    routes:[{
        path:"/login",
        component:WaiterPage
    }]
});

export default router;
