import request from "@/network/request";

export function getTablesAverageTime(config){
    return request({
        url:'/jeecg-boot/api/tables/tablesAverageTime',
        method:'post',
        data:config
    })
}

export function getUseCountData(config){
    return request({
        url:'/jeecg-boot/api/tables/tablesUseCountData',
        method:'post',
        data: config
    })
}

export function tablesTimeCountData(config){
    return request({
        url:'/jeecg-boot/api/tables/tablesTimeCountData',
        method:'post',
        data: config
    })
}

export function tablesAverageInUseTimeData(config){
    return request({
        url:'/jeecg-boot/api/tables/tablesAverageInUseTimeData',
        method:'post',
        data:config
    })
}

export function tablesStatusChangeData(config){
    return request({
        url:'/jeecg-boot/api/tables/tablesStatusChangeData',
        method:'post',
        data:config
    })
}



