import axios from "axios";



axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
// axios.defaults.timeout = 30000

// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true

// 拦截器
axios.interceptors.request.use(config=>{
    // TODO 这里要改成登录端口
    if(config.url=='/jeecg-boot/api/user/login'){
        return config;
    }

    if (localStorage.getItem("token") == null) {
      location.replace("/#/login");
    }

    //设置token
    if (localStorage.getItem("token") != null) {
        config.headers.setAuthorization(localStorage.getItem("token"));
        return config
    }

}, error => {
    return Promise.reject(error)
})
export default axios
