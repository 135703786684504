<template>
  <HelloWorld msg="Welcome to Your Vue.js App"/>
</template>

<script>
import HelloWorld from './components/MainPage.vue'
// import HelloWorld from './components/MiddleComponent.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff; /* Change text color to white for visibility */
  margin-top: 60px;
}
html{
  background-color: #000000; /* Change background color to dark */
}
</style>
