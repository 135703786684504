<template>
  <div class="middles">
    <div style="margin-left: 30px">
      <div style="margin-bottom: 10px">平均回転率</div>
      <div style="font-size: 40px">{{ total }}</div>
      <div  id="a">Pets:{{pets}}</div>
      <div  id="b">Pri:{{ pri }}</div>
    </div>
    <div style="margin-left: 150px">
      <div style="margin-bottom: 10px">空席累積時間</div>
      <div style="font-size: 40px">{{ freeTime.total }} Hrs.</div>
      <div>Pets:{{ freeTime.pets }}</div>
      <div>Pri:{{ freeTime.pri }}</div>
    </div>
    <div style="margin-left: 150px">
      <div style="margin-bottom: 10px">食事中累積時間</div>
      <div style="font-size: 40px">{{ eatTime.total }} Hrs.</div>
      <div>Pets:{{ eatTime.pets }}</div>
      <div>Pri:{{ eatTime.pri }}</div>
    </div>
    <div style="margin-left: 150px">
      <div style="margin-bottom: 10px">リセット累積時間</div>
      <div style="font-size: 30px">{{clearTime.total}} Hrs.</div>
      <div>Pets:{{ clearTime.pets }}</div>
      <div>Pri:{{ clearTime.pri }}</div>
    </div>
  </div>
</template>



<script>
import {fr} from "date-fns/locale";
import {getTablesAverageTime} from "@/api/tables";
import emitter from "@/utils/emitter";

export default {
  name: "MiddleComponent",
  computed: {
    fr() {
      return fr
    }
  },
  components: {

  },
  data() {
    return {
      // 平均回転率
      pri: 0,
      pets: 0,
      total:0,
      freeTime:{
        total: 0,
        pri: 0,
        pets: 0
      },
      eatTime:{
        pri: 0,
        pets: 0,
        total: 0,
      },
      clearTime:{
        pri: 0,
        pets: 0,
        total: 0,
      }
    }
  },
  methods:{
    getTime(mode,first,last){
      getTablesAverageTime({
        mode: mode,
        first:first,
        last: last,
      }).then((res)=>{
        let items=res.data.result;
        // 避免多次出现错误提示
        if(items==null){
          this.$toast.error("データが空です");
          localStorage.setItem("timeLimite","true");
          this.pets = 0;
          this.pri = 0;
          this.total = 0;

          this.clearTime.pets=0;
          this.clearTime.pri=0;
          this.clearTime.total=0;

          this.eatTime.pets=0;
          this.eatTime.pri=0;
          this.eatTime.total=0;

          this.freeTime.pets=0;
          this.freeTime.pri=0;
          this.freeTime.total=0;
        }else if(items!=null){
          this.pets = items.averageUsageRate[0];
          this.pri = items.averageUsageRate[1];
          this.total = items.averageUsageRate[2];

          this.clearTime.pets=items.resetCumulativeTime[0];
          this.clearTime.pri=items.resetCumulativeTime[1];
          this.clearTime.total=items.resetCumulativeTime[2];

          this.eatTime.pets=items.inUseCumulativeTime[0];
          this.eatTime.pri=items.inUseCumulativeTime[1];
          this.eatTime.total=items.inUseCumulativeTime[2];

          this.freeTime.pets=items.vacantCumulativeTime[0];
          this.freeTime.pri=items.vacantCumulativeTime[1];
          this.freeTime.total=items.vacantCumulativeTime[2];
        }
      })
    },

    formattedDate(timestamp){
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate
    },
  },
  created() {
    emitter.on("set_data",(args)=>{
      if(args[0]==null){
        args[0]="all";
      }
      //第一次进来是今天
      if(args[2]==null){
        let time=new Date();
        let dateString = time.getTime();
        args[2]= this.formattedDate(dateString);
      }
      //七天前时间
      if(args[1]==null) {
        let time=new Date();
        let dateString = time.getTime();
        const result = new Date();
        result.setTime(dateString - 6 * 24 * 60 * 60 * 1000);
        args[1] = this.formattedDate(result);
      }
       this.getTime(args[0],args[1],args[2]);
    })

    emitter.on("login",()=>{
      //第一次进来是今天
      let time=new Date();
      let dateString = time.getTime();
      let last= this.formattedDate(dateString);

      //七天前时间
      const result = new Date();
      result.setTime(dateString - 6 * 24 * 60 * 60 * 1000);
      let first = this.formattedDate(result);

      this.getTime("all",first,last);
    })
  }
}
</script>

<style scoped>
.middles{
  width: 100%;
  display: flex;
  font-size: 20px;
  text-align: left;
  margin-top: 50px;
}

#a{
  color: red;
}

#b{
  color: green;
}
</style>
