import request from "@/network/request";

export function getToken(config){
    return request({
        url:'/jeecg-boot/api/user/token',
        method:'post',
        data:config
    })
}

export function login(config){
    return request({
        url:'/jeecg-boot/api/user/login',
        method:'post',
        data:config
    })
}
