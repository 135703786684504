<template>
  <div class="table-usage">
    <!-- Display the table usage data here -->

    <div id="chart">
      <ApexChart type="heatmap" height="350" width="950" :options="chartOptions" :series="series"></ApexChart>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import {tablesStatusChangeData} from "@/api/tables";
import emitter from "@/utils/emitter";
export default {
  name: 'TableUsage',
  components: {
    ApexChart: VueApexCharts,
  },
  data() {
    return {
      // Initialize your table usage data here
      series: [],
      chartOptions: {
        yaxis: {
          labels: {
            style: {
              colors: '#ffffff'
            }
          }
        },
        xaxis: {
          type: 'datetime',
          categories: [],
          labels: {
            style: {
              colors: '#ffffff'
            }
          }
        },
        markers: {
          colors: ['#000000']
        },
        chart: {
          height: 350,
          type: 'heatmap',
        },
        grid: {
          row: {
            colors: ['#000000']
          },
          column: {
            colors: ['#000000']
          }
        },
        dataLabels: {
          enabled:false,
          style: {
            colors: ['#000000']
          }
        },
        legend: {
          position: 'right',
          offsetX: 0,
          offsetY: 50,
          labels: {
            colors: '#ffffff'
          },
          width: 100,
          horizontalAlign: 'center',

        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 1,
            radius: 0,
            useFillColorAsStroke: true,
            colorScale: {
              ranges: [{
                from: 0,
                to: 0,
                color: '#0a0a0a',
                name: 'データなし'
               },
                {
                  from: 1,
                  to:1,
                  color: '#fcfbf9',
                  name: '空席'
                },
                {
                  from: 2,
                  to:2,
                  color: '#faa220',
                  name: '食事中'
                },
                {
                  from: 3,
                  to:3,
                  color: '#8e26ef',
                  name: 'リセット'
                }
              ]
            }
          }
        },
        title: {
          text: 'テーブル状態のモニタリング',
          style: {
            fontSize:  '22px',
            fontWeight:  'bold',
            color:  '#ffffff'
          },
        },
      },
    }
  },
  methods:{
    getDayTablesStatus(mode,first,last){
      tablesStatusChangeData({
        mode: mode,
        first:first,
        last: last,
      }).then((res)=>{
        this.series=res.data.result;
      })
    },
    formattedDate(timestamp){
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate
    }
  },
  created() {
    emitter.on("set_data",(args)=>{
      if(args[0]==null){
        args[0]="all";
      }
      //第一次进来是今天
      if(args[2]==null){
        let time=new Date();
        let dateString = time.getTime();
        args[2]= this.formattedDate(dateString);
      }
      //七天前时间
      if(args[1]==null) {
        let time=new Date();
        let dateString = time.getTime();
        const result = new Date();
        result.setTime(dateString - 6 * 24 * 60 * 60 * 1000);
        args[1] = this.formattedDate(result);
      }
       this.getDayTablesStatus(args[0],args[1],args[2]);
    })

    emitter.on("login",()=>{
      //第一次进来是今天
      let time=new Date();
      let dateString = time.getTime();
      let last= this.formattedDate(dateString);

      //七天前时间
      const result = new Date();
      result.setTime(dateString - 6 * 24 * 60 * 60 * 1000);
      let first = this.formattedDate(result);

      this.getDayTablesStatus("all",first,last);
    })
  }
}
</script>

<style scoped>
/* Add your CSS here */
</style>
